import React from 'react';
import {Link, graphql} from 'gatsby';
import {getImage, withArtDirection, StaticImage} from 'gatsby-plugin-image';
// Component imports
import Layout from '@components/structure/Layout.jsx';
import Hero from '@components/content/hero.jsx';
import Anchor from '@components/content/Anchor.jsx';
import Video from '@components/content/video/Video.jsx';
import StickyTray from '@components/content/StickyTray';
import Quote from '@components/content/quote';
import ISI from '@components/content/isi/ISI.jsx';
import ISIHead from '@components/content/isiHead';

// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)
const ProgramResourcesRegistered = ({data}) => {
	const heroImage = withArtDirection(
		getImage(data.heroLarge), [
		{
			media: "(max-width: 600px)",
			image: getImage(data.heroSmall),
		},
		{
			media: "(max-width: 1200px)",
			image: getImage(data.heroMedium),
		},

	])
	const signatureImage = getImage(data.signature);
	const staciPortrait = getImage(data.staciPortrait);
	const brookePortrait = getImage(data.brookePortrait);

	return(
	<Layout pageClassName="resources-for-patients">
		<SEO title='Program Resources for Patients | Aurinia Alliance&reg;' description='' />
		<Hero heading={['Welcome to your Aurinia Alliance',<sup key={'sup'}>&reg;</sup>,' community']} background={heroImage} signature={signatureImage} signatureDisc={[<p key='name'>Suriya, <br className='mobile-only'/>started treatment 2021</p>]}>
			<p>In addition to getting connected with your Nurse Case Manager, you now have access to all of the resources Aurinia Alliance offers.</p>
		</Hero>
		<section className="welcome-kit welcome-kit--registered cta-row cta-row--white">
			<div className="wrapper">
				<h2>The Welcome Kit</h2>
				<div className="welcome-kit__img--mobile">
						<StaticImage src="../../images/welcome-kit.png" alt='LUPKYNIS&reg; (voclosporin) Welcome Kit'></StaticImage>
					</div>
				<p className='welcome-kit__description'>The Welcome Kit from your dedicated Nurse Case Manager has resources to help you get started with LUPKYNIS<sup>&reg;</sup>. Inside you'll find:</p>
				<div className='welcome-kit__container'>
					<div className="welcome-kit__list">
						<ul>
							<li>
								<h3>A Welcome Brochure</h3>
								<p>With helpful information about taking LUPKYNIS and how to get support from Aurinia Alliance.</p>
								<p><a href="/pdfs/welcome-brochure.pdf" target={'_blank'} className='download'><strong>Download Now</strong></a></p>
							</li>
							<li>
								<h3>An Instructional Card</h3>
								<p>To help you open your LUPKYNIS blister packs.</p>
							</li>
							<li>
								<h3>A LUPKYNIS Medication Carrier</h3>
								<p>Designed by someone living with lupus nephritis, so you can stylishly store and carry your medication. It's water resistant and has a pocket to store important information.</p>
							</li>
							<li>
								<h3>An Aurinia Alliance Magnet and Dry Erase Marker</h3>
								<p>Where you can track your daily doses and jot down appointments and reminders.</p>
							</li>
						</ul>
					</div>
					<div className="welcome-kit__img">
						<StaticImage src="../../images/welcome-kit.png" alt='LUPKYNIS&reg; (voclosporin) Welcome Kit'></StaticImage>
					</div>
				</div>
			</div>
		</section>
		<section className="cta-row cta-row--navy-blue">
			<div className="wrapper">
				<h2>Doctor Discussion Guide</h2>
				<p>Feel confident asking your doctor questions at your next appointment. <br/>Our downloadable doctor discussion guide can help you start the conversation.</p>
				<a className="btn btn--white" href={'/pdfs/doctor-discussion-guide.pdf'} target="_blank">DOWNLOAD THE GUIDE</a>
			</div>
		</section>
		<section className="see-others cta-row cta-row--white">
			<div className="wrapper">
				<div className='flex-container flex-container--left'>
					<div className="flex-container__content">
						<h2 className='desktop-only'>See how other people are living with lupus nephritis and taking LUPKYNIS</h2>
						<p><em>"Getting questions answered would've really been difficult without the extra help from my Nurse Case Manager."</em></p>
						<p className='patient-name'><strong>- Jennifer, started treatment in 2021</strong></p>
						<Anchor linkClass="btn btn--purple" link="https://www.lupkynis.com/taking-lupkynis" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">LEARN MORE</Anchor>
					</div>
					<div className="flex-container__content">
						<h2 className='mobile-only'>See how other people are living with lupus nephritis and taking LUPKYNIS</h2>
						<Video
							src='/videos/the-common-thread-of-lupus-nephritis-part2.mp4'
							type='video/mp4'
							poster='/images/watch-real-people-stories-poster-pt-2.png'
							title='Watch real people share their stories' controlsList='nodownload'
						/>
					</div>

				</div>
			</div>
		</section>
		<section className="reminders">
			<div className="wrapper">
				<div className="reminders__container">
					<h2>A few reminders for taking LUPKYNIS</h2>
					<div className="reminders__content">
						<StaticImage className='reminders-icon' src="../../images/tray-icon.png" width={100} alt=''></StaticImage>
						<p>Only remove the capsules you are about to take. Leave the others sealed to help maintain the quality of the capsules.</p>
					</div>
					<div className="reminders__content">
						<StaticImage className='reminders-icon' src="../../images/capsule-icon.png" width={100} alt=''></StaticImage>
						<p>Capsules should be swallowed whole. Do not break, crush, chew or dissolve LUPKYNIS capsules.</p>
					</div>
					<div className="reminders__content">
						<StaticImage className='reminders-icon' src="../../images/clock-icon.png" width={100} alt=''></StaticImage>
						<p>Take LUPKYNIS 8-12 hours apart. Make sure to take it on an empty stomach, either 1 hour before or 2 hours after a meal, and as close to 12 hours between doses as possible.</p>
					</div>
					<div className="reminders__content">
						<StaticImage className='reminders-icon' src="../../images/calandar-icon.png" width={100} alt=''></StaticImage>
						<p>Missed a dose? Take it as soon as possible within 4 hours of the missed dose. If more than 4 hours have passed, wait to take the next scheduled dose. Do not double up doses to make up for a missed dose.</p>
					</div>
				</div>
			</div>
		</section>
		<section className='faq cta-row cta-row--white'>
			<div className="wrapper">
				<h2>Frequently asked questions</h2>
				<div className="faq__content">
					<h3>How does LUPKYNIS work?</h3>
					<p>LUPKYNIS works to calm your immune system, which is causing your body to attack the cells of your kidneys. It helps reduce inflammation and protect specific cells of the kidneys to keep them functioning.</p>
				</div>
				<div className="faq__content">
					<h3>What are the most common side effects while taking LUPKYNIS?</h3>
					<p>Side effects included diarrhea, headache, cough, urinary tract infection, stomach pain, heartburn, and loss of hair (alopecia). These are not all the possible side effects of LUPKYNIS. Talk to your doctor about any side effects you may be experiencing. Please see the full list of possible side effects in the <a href="https://www.auriniapharma.com/lupkynis-prescribing-information" target='_blank' className='faq-link'>Prescribing Information</a>, including Boxed Warning, and <a href="https://www.auriniapharma.com/lupkynis-medication-guide" target='_blank' className='faq-link'>Medication Guide</a> <a href="https://www.lupkynis.com/pdfs/spanish-medication-guide.pdf" target='_blank' className='faq-link'>(Guía del medicamento)</a>.</p>
				</div>
				<Anchor linkClass="btn btn--purple" link="https://www.lupkynis.com/resources#FAQ" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">SEE MORE FAQS</Anchor>
			</div>
		</section>
		<section className="instructions cta-row cta-row--white">
			<div className="wrapper">
				<h2>How to open your LUPKYNIS blister pack</h2>
				<div className="instructions__container">
					<div className="instructions__content">
						<StaticImage src='../../images/instruction1.png' width={372} className='instructions__img' alt=''></StaticImage>
						<p className='instructions__text'>Unfold the wallet and start with one panel on the <strong>teal perforated cardboard side</strong>.</p>
					</div>
					<div className="instructions__content">
						<StaticImage src='../../images/instruction2.png' width={372} className='instructions__img' alt=''></StaticImage>
						<p className='instructions__text'>Press down at the edge of the perforated cardboard circle to <strong>break through the cardboard</strong>.</p>
					</div>
					<div className="instructions__content">
						<StaticImage src='../../images/instruction3.png' width={372} className='instructions__img' alt=''></StaticImage>
						<p className='instructions__text'><strong>Press the foil up from behind the cardboard circle</strong> to push the capsule forward. Peel the cardboard circle back and remove the capsule.</p>
					</div>
				</div>
				<h3>Only take the dose that has been prescribed by your doctor.</h3>
			</div>
		</section>
		<Quote portrait={staciPortrait} patientInfo={"Staci, started treatment 2021"} position={'quote__container--left'} className={'quote--light-purple'} backgroundImage={false}>
			<p className='quote-text'>"I just really appreciate that my case manager spends the time to explain everything to me regarding the medication and the whole process of getting started on a new medication. I also like that she checks in with me on a regular basis."</p>
		</Quote>
		<Quote portrait={brookePortrait} patientInfo={"Brooke H., RN Nurse Case Manager"} position={'quote__container--right'} className={'quote--grey'} backgroundImage={true}>
			<p className='quote-text'>"I'm here to help guide you through your journey. I love being able to educate and involve you in your care as much as possible, and advocate for you and giving you a voice when it matters most."</p>
		</Quote>
		<section className="connections cta-row cta-row--white">
			<div className="wrapper">
				<h2>You're not in&nbsp;this&nbsp;alone</h2>
				<div className='flex-container flex-container--right'>
					<div className="flex-container__content">
						<h3 className='mobile-only'>Connect with a lupus nephritis mentor</h3>
						<StaticImage src='../../images/connect-with-ln-mentor.jpg' width={792} alt=''></StaticImage>
					</div>
					<div className="flex-container__content">
						<h3 className='desktop-only'>Connect with a lupus nephritis mentor</h3>
						<p><strong>Lupus Nephritis Peer Connect<sup>&trade;</sup></strong> is a mentor program that allows you to speak with someone who is also living with lupus nephritis and who might understand what you're going through. Our peer mentors volunteer for one-on-one calls to answer your questions, offer encouragement, and share their own personal experiences with lupus nephritis and treatment with LUPKYNIS.</p>
						<Anchor linkClass="btn btn--purple" link="https://lnpeerconnect.com/" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">REQUEST A MENTOR</Anchor>
					</div>
				</div>
				<div className='flex-container flex-container--left'>
					<div className="flex-container__content">
						<h3 className='mobile-only'>Connect with organizations</h3>
						<StaticImage src='../../images/connect-with-organizations.jpg' width={538} alt=''></StaticImage>
					</div>
					<div className="flex-container__content">
						<h3 className='desktop-only'>Connect with organizations</h3>
						<p>From raising awareness about lupus nephritis to helping you feel connected and supported by others who are fighting the same fight, joining peer communities and support networks is a great way to get involved. You can join <strong>local and regional organizations</strong> in your area, as well as <strong>national groups</strong> that serve communities all over the country.</p>
						<Anchor linkClass="btn btn--purple" link="https://www.lupkynis.com/resources#Organizations" requiresExitModal={true} isExternal={true} relationship="noopener noreferrer">FIND A COMMUNITY</Anchor>
					</div>
				</div>
			</div>
		</section>
		<StickyTray buttonText={''} addedClass={'sticky-tray'} targetId="#ISI">
			<div className="isi__content">
				<ISIHead></ISIHead>
			</div>
		</StickyTray>
		<ISI></ISI>
	</Layout>
	)
};

export default ProgramResourcesRegistered;

export const pageQuery = graphql`
  query ProgramResourcesRegisteredHeroQuery {
    heroSmall: file(relativePath: {eq: "hero/resources-for-patients-hero--s.png"}) {
		childImageSharp {
		  gatsbyImageData(width:375)
		}
	},
	heroMedium: file(relativePath: {eq: "hero/resources-for-patients-hero--m.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1440)
		}
	},	
	heroLarge: file(relativePath: {eq: "hero/resources-for-patients-hero--l.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1500)
		}
	},
	staciPortrait: file(relativePath: {eq: "portrait/staci.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
	brookePortrait: file(relativePath: {eq: "portrait/brooke.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
}
`
